import Box from 'components/common/basic/containers/Box';
import CheckCircleTwoTone from '@ant-design/icons/CheckCircleTwoTone';
import DivFlex from 'components/common/basic/containers/DivFlex';
import Img from 'components/common/basic/Img';
import PageContainer from 'components/common/basic/containers/PageContainer';
import React from 'react';
import Text from 'components/common/basic/Text/Text';
import theme from 'style/theme';

import logo from 'assets/logo.png';

import mainImage from '../assets/main.png';

const MainLanding = () => {
  return (
    <PageContainer>
      <DivFlex
        flexDirectionT='column'
        justifyContent='space-around'
        alignItems='flex-start'
      >
        <Img src={mainImage} alt='main' m='-6rem 0 0' maxW='100%' />
        <Box>
          <Text type='title' fw='bold' m='4rem 0' lh='4rem'>
            Progresser en Chinois en T’amusant !
          </Text>
          <DivFlex alignItems='center'>
            <CheckCircleTwoTone
              twoToneColor={theme.colors.success}
              style={{ fontSize: '2.2rem' }}
            />
            <Text fw='lighter' type='h2' m='3rem 2rem 3rem'>
              Des cours en vidéos et textes
            </Text>
          </DivFlex>
          <DivFlex alignItems='center'>
            <CheckCircleTwoTone
              twoToneColor={theme.colors.success}
              style={{ fontSize: '2.2rem' }}
            />
            <Text fw='lighter' type='h2' m='3rem 2rem 3rem'>
              Des méthodes simples et efficaces pour apprendre
            </Text>
          </DivFlex>
          <DivFlex alignItems='center'>
            <CheckCircleTwoTone
              twoToneColor={theme.colors.success}
              style={{ fontSize: '2.2rem' }}
            />
            <Text fw='lighter' type='h2' m='3rem 2rem 3rem'>
              Des contenus exclusifs pour pratiquer
            </Text>
          </DivFlex>
          <Box w='100%' textAlign='center'>
            <Img src={logo} alt='logo' w='20rem' />
          </Box>
        </Box>
      </DivFlex>
    </PageContainer>
  );
};

export default MainLanding;
