const theme = {
  colors: {
    main: '#0099ff',
    secondary: '#f96',
    background: '#ecebeb66',
    black: '#081029',
    gray: '#585858',
    lightGray: '#C4C4C4',
    white: '#FFFFFF',
    transparent: '#ffffff00',
    green: '#19A979',
    greenLight: '#22DBA3',
    success: '#92B73B',
    successLight: '#F4F8EB',
    error: '#f92626',
  },
  fontFamilies: {
    main: 'Dosis',
  },
};

export default theme;
