import Header from 'components/Header';
import React from 'react';

import { Body } from './style';

const Layout = ({ children }) => {
  return (
    <>
      <Header fixed='true' />
      <Body>{children}</Body>
    </>
  );
};

export default Layout;
