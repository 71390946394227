import theme from 'style/theme';

export const overwrites = `
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled), .ant-select-item-option-selected:hover:not(.ant-select-item-option-selected-disabled) {
    background-color: ${theme.colors.mainLight};
  }

  .ant-select-item-option-selected-active:not(.ant-select-item-option-selected-disabled) {
    background-color: ${theme.colors.mainLight};
  }
  
  .ant-picker-dropdown {
    & .ant-picker-cell-selected {
      & .ant-picker-cell-inner {
        background: ${theme.colors.main};
        border-radius: 50%;
      }
    }

    & .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
      border: none;
    }
  }

  .ant-select-dropdown div div {
    ::-webkit-scrollbar {
      width: 0.3rem;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background: ${theme.colors.lightGray};
      border-radius: 0.3rem;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${theme.colors.gray};
    }
  }

  .ant-btn-primary {
    background-color: ${theme.colors.main};
    border: none;

    &:hover {
      background-color: ${theme.colors.main};
    }
  }

  .ant-message {
    top: unset;
    bottom: 2rem;

    & .ant-message-notice-content {
      background-color: ${theme.colors.gray};
      color: ${theme.colors.white};
    }

    & .anticon-check-circle {
      display: none;
    }
  }
`;
