import { devices } from 'style/constants';
import AntCard from 'antd/lib/card';
import React from 'react';
import styled from 'styled-components';

export const CardStyled = styled(AntCard)`
  box-shadow: 0px 2px 4px rgba(8, 16, 41, 0.16);
  color: ${({ theme }) => theme.colors.black};
  & .ant-card-body {
    height: 100%;
    padding: ${({ p }) => p};
    @media ${devices.desktop} {
      padding: ${({ p, pd }) => pd || p};
    }
    @media ${devices.laptop} {
      padding: ${({ p, pl, pd }) => pl || pd || p};
    }
    @media ${devices.tablet} {
      padding: ${({ p, pt, pl, pd }) => pt || pl || pd || p};
    }
    @media ${devices.mobile} {
      padding: ${({ p, pm, pt, pl, pd }) => pm || pt || pl || pd || p};
    }
  }

  @media ${devices.desktop} {
    margin: ${({ m, md }) => md || m};
  }
  @media ${devices.laptop} {
    margin: ${({ m, ml, md }) => ml || md || m};
  }
  @media ${devices.tablet} {
    margin: ${({ m, mt, ml, md }) => mt || ml || md || m};
  }
  @media ${devices.mobile} {
    margin: ${({ m, mm, mt, ml, md }) => mm || mt || ml || md || m};
  }
`;

CardStyled.defaultProps = {
  m: '0',
  md: null,
  ml: null,
  mt: null,
  mm: null,
  p: '3rem 2.8rem',
  pd: null,
  pl: null,
  pt: null,
  pm: null,
};

export const Card = ({ children, ...props }) => (
  <CardStyled {...props}>{children}</CardStyled>
);

Card.defaultProps = {
  loading: false,
};

// If a child of the ContentCard (CompactCard or the WideCard) has the attribute data-fullwidth
// then it will take the full width of the card, neglecting the padding

const contentCardPadding = 4; /* In rem */

const ContentCard = styled(Card)`
  box-shadow: 0px 4px 8px rgba(8, 16, 41, 0.16);
  color: ${({ theme }) => theme.colors.black};
  & [data-fullwidth] {
    margin-left: -${contentCardPadding}rem;
    width: calc(100% + ${contentCardPadding * 2}rem);
  }
  & [data-attop] {
    margin-top: -${contentCardPadding}rem;
  }
`;

ContentCard.defaultProps = {
  m: '4.5rem',
  p: `${contentCardPadding}rem`,
};

export const CompactCardNormalWidth = 442;

export const CompactCard = styled(ContentCard)`
  @media ${devices.desktop} {
    width: ${CompactCardNormalWidth}px;
  }
  @media ${devices.laptop} {
    width: ${CompactCardNormalWidth}px;
  }
  @media ${devices.mobile} {
    width: 90%;
  }
`;

export const WideCard = styled(ContentCard)`
  width: 93%;
  min-width: 30%;
  @media ${devices.mobile} {
    width: 90%;
  }
`;

WideCard.defaultProps = {
  mm: '4.5rem auto',
};
