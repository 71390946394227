import { devices } from 'style/constants';
import styled from 'styled-components';

const Img = styled.img`
  user-select: none;
  width: ${({ w }) => w};
  max-width: ${({ maxW }) => maxW};
  height: ${({ h }) => h};
  cursor: ${({ pointer }) => pointer && 'pointer'};
  @media ${devices.desktop} {
    margin: ${({ m, md }) => md || m};
  }
  @media ${devices.laptop} {
    margin: ${({ m, ml, md }) => ml || md || m};
  }
  @media ${devices.tablet} {
    margin: ${({ m, mt, ml, md }) => mt || ml || md || m};
    width: ${({ w, wt }) => wt || w};
  }
  @media ${devices.mobile} {
    margin: ${({ m, mm, mt, ml, md }) => mm || mt || ml || md || m};
  }
`;

Img.defaultProps = {
  m: 0,
  md: null,
  ml: null,
  mt: null,
  mm: null,
};

export default Img;
