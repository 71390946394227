import DivFlex from 'components/common/basic/containers/DivFlex';
import Img from 'components/common/basic/Img';
import React from 'react';
import Text from 'components/common/basic/Text/Text';

import logo from 'assets/logo.png';

import { Card } from './style';

const Header = ({ fixed }) => {
  const onClickYoutube = () => {
    window.open('https://www.youtube.com/channel/UCfXMphHOyK6ienqoi9e73iA');
  };

  return (
    <header>
      <Card p='0.8rem 2.4rem' fixed={fixed}>
        <DivFlex alignItems='center' justifyContent='space-between' h='100%'>
          <DivFlex alignItems='center' h='100%'>
            <Img src={logo} alt='logo' h='100%' pointer />
            <Text fw='bold' m='0 0 0 2rem' type='h3'>
              Chinois Avec Nicole
            </Text>
          </DivFlex>
          <Img
            src='https://icons.iconarchive.com/icons/dtafalonso/android-l/256/Youtube-icon.png'
            w='6rem'
            title='My Youtube Channel'
            onClick={onClickYoutube}
            pointer
          />
        </DivFlex>
      </Card>
    </header>
  );
};

export default Header;
