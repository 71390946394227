import { devices } from 'style/constants';
import styled from 'styled-components';
import theme from 'style/theme';

const getFontSize = (type) =>
  type === 'p'
    ? '1.4rem'
    : type === 'small'
    ? '1.2rem'
    : type === 'title'
    ? '3.2rem'
    : type === 'main'
    ? '5.6rem'
    : type === 'big'
    ? '4rem'
    : type === 'h1'
    ? '2.4rem'
    : type === 'h2'
    ? '1.8rem'
    : type === 'h3'
    ? '1.6rem'
    : '1.4rem';

const Text = styled.p`
  font-size: 1.8rem;
  color: ${({ color }) => color};
  text-align: ${({ align }) => align};
  line-height: ${({ lh }) => lh};
  font-size: ${({ type }) => getFontSize(type)};
  font-weight: ${({ type, fw }) =>
    fw ? fw : type === 'h1' ? '600' : 'normal'};
  cursor: ${({ pointer }) => pointer && 'pointer'};
  text-transform: ${({ textTransform }) => textTransform};
  user-select: ${({ noSelect }) => noSelect && 'none'};
  display: ${({ display }) => display};

  @media ${devices.desktop} {
    margin: ${({ m, md }) => md || m};
    font-size: ${({ type, typed }) => getFontSize(typed || type)};
  }
  @media ${devices.laptop} {
    margin: ${({ m, ml, md }) => ml || md || m};
    font-size: ${({ type, typel, typed }) =>
      getFontSize(typel || typed || type)};
  }
  @media ${devices.tablet} {
    margin: ${({ m, mt, ml, md }) => mt || ml || md || m};
    font-size: ${({ type, typet, typel, typed }) =>
      getFontSize(typet || typel || typed || type)};
  }
  @media ${devices.mobile} {
    margin: ${({ m, mm, mt, ml, md }) => mm || mt || ml || md || m};
    font-size: ${({ type, typem, typet, typel, typed }) =>
      getFontSize(typem || typet || typel || typed || type)};
    display: ${({ hideOnMobile, display }) =>
      hideOnMobile ? 'none' : display};
  }
`;

Text.defaultProps = {
  m: '1rem 0',
  md: null,
  ml: null,
  mt: null,
  mm: null,
  type: 'p',
  lh: '21px',
  color: theme.black,
  hideOnMobile: false,
  pointer: false,
  noSelect: false,
  textTransform: 'inherit',
  align: 'inherit',
  display: 'block',
};

export default Text;
