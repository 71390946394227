import { devices } from 'style/constants';
import styled from 'styled-components';

const DivFlex = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  width: ${({ w }) => w};
  min-width: ${({ minW }) => minW};
  height: ${({ fullHeight, h }) => (fullHeight ? '100%' : h)};
  min-height: ${({ minH }) => minH};
  background-color: ${({ bgc }) => bgc};
  border-radius: ${({ br }) => br};
  border: ${({ b }) => b};
  cursor: ${({ pointer }) => pointer && 'pointer'};

  @media ${devices.desktop} {
    margin: ${({ m, md }) => md || m};
  }
  @media ${devices.laptop} {
    margin: ${({ m, ml, md }) => ml || md || m};
    flex-direction: ${({ flexDirectionL, flexDirection }) =>
      flexDirectionL || flexDirection};
    align-items: ${({ alignItemsL, alignItems }) => alignItemsL || alignItems};
  }
  @media ${devices.miniLaptop} {
    margin: ${({ m, mml, ml, md }) => mml || ml || md || m};
    flex-direction: ${({ flexDirectionML, flexDirectionL, flexDirection }) =>
      flexDirectionML || flexDirectionL || flexDirection};
    justify-content: ${({ justifyContentML, justifyContent }) =>
      justifyContentML || justifyContent};
    align-items: ${({ alignItemsML, alignItemsL, alignItems }) =>
      alignItemsML || alignItemsL || alignItems};
  }

  @media ${devices.tablet} {
    margin: ${({ m, mt, mml, ml, md }) => mt || mml || ml || md || m};
    flex-direction: ${({ flexDirectionT, flexDirectionL, flexDirection }) =>
      flexDirectionT || flexDirectionL || flexDirection};
    align-items: ${({ alignItemsT, alignItemsL, alignItems }) =>
      alignItemsT || alignItemsL || alignItems};
  }

  @media ${devices.mobile} {
    flex-direction: ${({
      flexDirectionM,
      flexDirectionT,
      flexDirectionML,
      flexDirectionL,
      flexDirection,
    }) =>
      flexDirectionM ||
      flexDirectionT ||
      flexDirectionL ||
      flexDirectionML ||
      flexDirection};
    justify-content: ${({
      justifyContentM,
      justifyContentML,
      justifyContent,
    }) => justifyContentM || justifyContentML || justifyContent};
    align-items: ${({
      alignItemsM,
      alignItemsT,
      alignItemsML,
      alignItemsL,
      alignItems,
    }) =>
      alignItemsM || alignItemsT || alignItemsML || alignItemsL || alignItems};
    margin: ${({ m, mm, mt, mml, ml, md }) => mm || mt || mml || ml || md || m};
  }
`;

DivFlex.defaultProps = {
  m: 0,
  md: null,
  ml: null,
  mt: null,
  mm: null,
  fullHeight: false,
};

export default DivFlex;