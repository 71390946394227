import Layout from 'hoc/Layout';
import MainLanding from 'pages/public/landing/main';
import React from 'react';

function App() {
  return (
    <Layout>
      <MainLanding />
    </Layout>
  );
}

export default App;
