import { createGlobalStyle } from 'styled-components';

import { overwrites } from './globalOverwrites';

export const GlobalStyle = createGlobalStyle`
  ::selection {
    background: ${({ theme }) => theme.colors.mainUnselected}
  }
  body,html {
    margin: 0;
    padding: 0;
  }
  *{
    box-sizing: border-box;
  }
  html {
    font-size: 62.5%;
  }
  body {
    font-family: ${({ theme }) =>
      theme.fontFamilies.main}, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #081029;
    line-height: 21px;
    
    ::-webkit-scrollbar {
      width: 1.75rem;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1; 
    }
    
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.lightGray}; 
      border-radius: 1rem;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.colors.gray};
    }
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }

  hr {
    border: ${({ theme }) => `.04rem solid ${theme.colors.lightGray}`};
  }

  ${overwrites}
`;
