import styled from 'styled-components';

import Box from '../Box';

export const pagePadding = '2.4rem';
export const reversePagePadding = '-2.4rem';

const PageContainer = styled(Box).attrs(() => ({
  p: pagePadding,
}))`
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.background};
`;

export default PageContainer;
