import { Card as AntCard } from 'components/common/basic/Card';
import styled from 'styled-components';

export const headerHeight = '8rem';

export const Card = styled(AntCard)`
  height: ${headerHeight};
  width: fill-available;
  position: ${({ fixed }) => (fixed === 'true' ? 'fixed' : 'absolute')};
  background-color: ${({ theme }) => theme.colors.transparentWhite};
  top: 0;
  left: 0;
  z-index: 10;
`;
