import { devices } from 'style/constants';
import styled from 'styled-components';

const Box = styled.div`
  display: ${({ display, hidden }) => (hidden ? 'none' : display)};
  background-color: ${({ bgc }) => bgc};
  border-radius: ${({ br }) => br};
  cursor: ${({ pointer }) => pointer && 'pointer'};
  border: ${({ b }) => b};
  border-bottom: ${({ bb }) => bb};
  border-top: ${({ bt }) => bt};
  border-right: ${({ br }) => br};
  border-left: ${({ bl }) => bl};
  height: ${({ h }) => h};
  min-height: ${({ minH }) => minH};
  flex: ${({ flex }) => flex};
  text-align: ${({ textAlign }) => textAlign};
  transform: ${({ transform }) => transform};
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  left: ${({ left }) => left};

  @media ${devices.desktop} {
    width: ${({ w, wd }) => wd || w};
    height: ${({ h, hd }) => hd || h};
    margin: ${({ m, md }) => md || m};
    padding: ${({ p, pd }) => pd || p};
  }
  @media ${devices.laptop} {
    width: ${({ w, wl, wd }) => wl || wd || w};
    height: ${({ h, hl, hd }) => hl || hd || h};
    margin: ${({ m, ml, md }) => ml || md || m};
    padding: ${({ p, pl, pd }) => pl || pd || p};
  }
  @media ${devices.miniLaptop} {
    width: ${({ w, wml, wl, wd }) => wml || wl || wd || w};
    height: ${({ h, hml, hl, hd }) => hml || hl || hd || h};
    margin: ${({ m, mml, ml, md }) => mml || ml || md || m};
    padding: ${({ p, pml, pl, pd }) => pml || pl || pd || p};
  }
  @media ${devices.tablet} {
    width: ${({ w, wt, wml, wl, wd }) => wt || wml || wl || wd || w};
    height: ${({ h, ht, hml, hl, hd }) => ht || hml || hl || hd || h};
    margin: ${({ m, mt, mml, ml, md }) => mt || mml || ml || md || m};
    padding: ${({ p, pt, pml, pl, pd }) => pt || pml || pl || pd || p};
  }
  @media ${devices.mobile} {
    width: ${({ w, wm, wml, wt, wl, wd }) => wm || wt || wml || wl || wd || w};
    height: ${({ h, hm, hml, ht, hl, hd }) => hm || ht || hml || hl || hd || h};
    margin: ${({ m, mm, mt, mml, ml, md }) => mm || mt || mml || ml || md || m};
    padding: ${({ p, pm, pt, pml, pl, pd }) =>
      pm || pt || pml || pl || pd || p};
  }
`;

Box.defaultProps = {
  m: 0,
  md: null,
  ml: null,
  mt: null,
  mm: null,
  p: 0,
  pd: null,
  pl: null,
  pt: null,
  pm: null,
  display: 'block',
  hidden: false,
};

export default Box;
