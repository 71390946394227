import { GlobalStyle } from 'style/globalStyle/index';
import { ThemeProvider } from 'styled-components';
import React from 'react';
import ReactDOM from 'react-dom';
import theme from 'style/theme';

import 'antd/dist/antd.css';

import * as serviceWorker from './serviceWorker';
import App from './App';

let app = <App />;

// Hook styled-components theme and GlobalStyle
app = (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <App />
  </ThemeProvider>
);

ReactDOM.render(
  <React.StrictMode>
    {app}
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
